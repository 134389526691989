import { FC } from 'react'
import { PAGE_TYPE, SEO } from '@corratech/pylot-seo'
import { useTranslation } from 'next-i18next'
import { RichContent } from '@corratech/pylot-rich-content'
import { useCmsPage } from '@pylot-data/hooks/cms/use-cms-page'
import { Component404 } from './404'
import { useUI } from '@corratech/pylot-ui/context'
import { CmsPage } from '@pylot-data/pylotschema'

export type Props = {
    identifier: string
    cmsPageData?: CmsPage | null | undefined
}

export const NotFound: FC<Props> = ({ identifier, cmsPageData }) => {
    const { LoadingIndicator } = useUI()
    const { t } = useTranslation(['common'])
    const { cmsPage, isValidating } = useCmsPage(identifier, {
        revalidateOnFocus: false,
        revalidateOnMount: true,
        ...(cmsPageData && {
            fallbackData: { data: { cmsPage: cmsPageData } }
        })
    })

    if (!cmsPageData && isValidating) {
        return <LoadingIndicator />
    }

    return (
        <>
            <SEO
                titleOverride={t('404|Page not found')}
                page={PAGE_TYPE.CMS}
                cmsPage={cmsPage}
            />
            {cmsPage ? (
                <div className="no-route-content">
                    <RichContent html={cmsPage?.content} />
                </div>
            ) : (
                <Component404 />
            )}
        </>
    )
}
