import type {
    GetStaticPropsResult,
    GetStaticPropsContext,
    InferGetStaticPropsType
} from 'next'
import { Layout } from '@components/common'
import { serverSideTranslations } from '@pylot-data/serverSideTranslations'
import React from 'react'
import getCMSPage from '@pylot-data/api/operations/get-cms'
import { CmsPage } from '@pylot-data/pylotschema'
import { getStoreConfig } from 'config'
import { NotFound } from '@components/404'

export async function getStaticProps({
    locale
}: GetStaticPropsContext): Promise<
    GetStaticPropsResult<{
        cmsPageData: CmsPage | null | undefined
        identifier: string
    }>
> {
    try {
        const config = getStoreConfig()
        const identifier = config?.base?.route?.cms_no_route!

        const cmsPageData = await getCMSPage({
            identifier: identifier,
            locale
        })

        return {
            props: {
                cmsPageData: cmsPageData ?? null,
                identifier,
                ...(await serverSideTranslations(locale!, ['common']))
            },
            revalidate: 14400
        }
    } catch (e) {
        console.log(
            'Error in getStaticProps in page with filepath /pages/404.tsx'
        )
        console.log(e)
        return {
            props: {
                cmsPageData: null,
                identifier: '',
                ...(await serverSideTranslations(locale!, ['common']))
            },
            revalidate: 14400
        }
    }
}

export default function Custom404({
    cmsPageData,
    identifier
}: InferGetStaticPropsType<typeof getStaticProps>): JSX.Element {
    return <NotFound identifier={identifier} cmsPageData={cmsPageData} />
}

Custom404.Layout = Layout
