import React from 'react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'

export const Component404: React.FC = () => {
    const { t } = useTranslation(['common'])
    return (
        <div className="text-center px-5">
            <h1 className="text-6xl">{t('404|Uh oh!')}</h1>
            <h3 className="text-3xl">
                {t('404|Something went wrong.')}{' '}
                <Link href="/" className="underline cursor-pointer">
                    {t('404|Go back to our homepage to continue shopping!')}
                </Link>
            </h3>
        </div>
    )
}
